import {makeStyles, Typography, Box, Icon} from '@material-ui/core';
import {useIntl} from 'react-intl';
import FooterLinkSection from './FooterLinkSection';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: '40px 100px',
      flexDirection: 'row',
    },
  },
  linkCategory: {
    fontFamily: theme.typography.primary.fontFamily,
    fontWeight: 600,
    marginBottom: '1rem',
  },
  link: {
    textDecoration: 'none',
    marginBottom: '1rem',
    display: 'block',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
  },
  socialLinkText: {
    textTransform: 'uppercase',
  },
  socialIcon: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: 8,
    color: theme.palette.common.white,
    borderRadius: '50%',
    height: 30,
    width: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkText: {
    fontWeight: 600,
    fontSize: '0.75rem',
    color: theme.palette.common.black,
  },
}));

const Link = ({href, text, linkClass, textClass, iconClass}) => (
  <a href={href} target="_blank" className={linkClass} rel="noreferrer">
    {iconClass && <Icon className={iconClass} />}
    <Typography className={textClass}>{text}</Typography>
  </a>
);

export default function FooterLinks() {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" className={classes.root}>
      <FooterLinkSection header={formatMessage({id: 'footer.links.chargingCalculator.header'})}>
        <Link
          href="/"
          text={formatMessage({id: 'footer.links.chargingCalculator.home'})}
          linkClass={classes.link}
          textClass={classes.linkText}
        />
        <Link
          href="/fleets"
          text={formatMessage({id: 'footer.links.chargingCalculator.fleetElectrification'})}
          linkClass={classes.link}
          textClass={classes.linkText}
        />
        <Link
          href="/sites"
          text={formatMessage({id: 'footer.links.chargingCalculator.sitePlanner'})}
          linkClass={classes.link}
          textClass={classes.linkText}
        />
      </FooterLinkSection>
      <FooterLinkSection header={formatMessage({id: 'footer.links.site.header'})}>
        <Link
          href="https://www.oru.com"
          text={formatMessage({id: 'footer.links.site.mainSite'})}
          linkClass={classes.link}
          textClass={classes.linkText}
        />
        <Link
          href="https://www.oru.com/our-energy-future/technology-innovation/electric-vehicles"
          text={formatMessage({id: 'footer.links.site.electricVehicles'})}
          linkClass={classes.link}
          textClass={classes.linkText}
        />
      </FooterLinkSection>
      <FooterLinkSection header={formatMessage({id: 'footer.links.social.header'})}>
        <Link
          href={formatMessage({id: 'footer.links.social.facebookLink'})}
          text={formatMessage({id: 'footer.links.social.facebook'})}
          linkClass={`${classes.link} ${classes.socialLink}`}
          textClass={`${classes.linkText} ${classes.socialLinkText}`}
          iconClass={`icon-facebook ${classes.socialIcon}`}
        />
        <Link
          href={formatMessage({id: 'footer.links.social.twitterLink'})}
          text={formatMessage({id: 'footer.links.social.twitter'})}
          linkClass={`${classes.link} ${classes.socialLink}`}
          textClass={`${classes.linkText} ${classes.socialLinkText}`}
          iconClass={`icon-twitter ${classes.socialIcon}`}
        />
        <Link
          href={formatMessage({id: 'footer.links.social.youtubeLink'})}
          text={formatMessage({id: 'footer.links.social.youtube'})}
          linkClass={`${classes.link} ${classes.socialLink}`}
          textClass={`${classes.linkText} ${classes.socialLinkText}`}
          iconClass={`icon-youtube ${classes.socialIcon}`}
        />
        <Link
          href={formatMessage({id: 'footer.links.social.linkedInLink'})}
          text={formatMessage({id: 'footer.links.social.linkedIn'})}
          linkClass={`${classes.link} ${classes.socialLink}`}
          textClass={`${classes.linkText} ${classes.socialLinkText}`}
          iconClass={`icon-linked-in ${classes.socialIcon}`}
        />
      </FooterLinkSection>
    </Box>
  );
}
