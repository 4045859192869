import {SEASONS} from '@generic/data/assumptions';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedOruStaticRateElements from './shared/sharedOruStaticRateElements';

// Service Classification ("SC") 25 Rate 3
const RATE_NAME = 'sc25Rate3';

export default ({isOnMarketBasedRate}) => ({
  name: RATE_NAME,
  title: `SC 25 Rate 3`,
  rateElements: [
    ...sharedOruStaticRateElements,
    ...getMarketRateElements(isOnMarketBasedRate),
    {
      rateElementType: 'DemandPerDay',
      name: 'As-Used Demand Charge',
      billingCategory: 'delivery',
      rateComponents: [
        // ORU does not describe all hours for demands, which may trigger warnings in console
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 0.4004 : 0.7091)),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22], // 8am to 11pm
        },
      ],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Contract Demand Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 6.76}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 500}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Merchant Function Charge (MFC)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00146}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Energy Cost Adjustment (ECA) - Demand',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.06}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Cost Adjustment (ECA) - Energy',
      billingCategory: 'delivery',
      rateComponents: [{charge: -0.00035}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Mandatory DAHP: Capacity Supply Charge',
      billingCategory: 'supply',
      rateComponents: [{charge: SEASONS.map((season) => (season === 'WINTER' ? 17.51 : 17.49))}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges',
      billingCategory: 'delivery',
      rateComponents: [{charge: 62.63}],
    },
  ],
});
