import {SEASONS} from '@generic/data/assumptions';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedOruStaticRateElements from './shared/sharedOruStaticRateElements';

// Service Classification ("SC") 2; Primary
const RATE_NAME = 'sc2P';

export default ({isOnMarketBasedRate}) => ({
  name: RATE_NAME,
  title: `SC 2`,
  rateElements: [
    ...sharedOruStaticRateElements,
    ...getMarketRateElements(isOnMarketBasedRate),
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 35}],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      name: 'Demand Delivery Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: SEASONS.map((season) => (season === 'WINTER' ? 9.5 : 17.12))}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Delivery Usage Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00786}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Dynamic Load Management Surcharge (DLM) (Added to ECA for billing purposes)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.06}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Energy Cost Adjustment (ECA) - Demand',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.25}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Cost Adjustment (ECA) - Energy',
      billingCategory: 'delivery',
      rateComponents: [{charge: -0.0001}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Merchant Function Charge (MFC)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00146}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 16.66 : 26.8}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges - Mandatory DAHP',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 62.63 : 0}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'MSC Energy Charge - Rate 2P, 3, 25 I, 25 II',
      billingCategory: 'supply',
      rateComponents: [{charge: isOnMarketBasedRate ? 0 : 0.04461}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Revenue Decoupling Mechanism Adj (RDM)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00267}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Usage Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: SEASONS.map((season) => (season === 'WINTER' ? 0.0315 : 0.04263))}],
    },
  ],
});
