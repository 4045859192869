import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import AccordionPanel from '@generic/components/AccordionPanel';
import ChargingRatesOverviewChart from '@generic/pages/ChargingCalculator/ChargingRatesOverviewChart';
import ChargingRateBreakdown from '@generic/pages/ChargingCalculator/ChargingRateBreakdown';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0',
    [theme.breakpoints.up('md')]: {
      padding: '0 2rem',
    },
  },
}));

export default function ElectricityCosts() {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  return (
    <Box>
      <AccordionPanel
        summary={formatMessage({id: 'electricRateExplainer.summary'})}
        details={formatMessage({id: 'electricRateExplainer.details'})}
      />

      <Box className={classes.content}>
        <ChargingRatesOverviewChart compareToFossil />

        <ChargingRateBreakdown />
      </Box>
    </Box>
  );
}
