import {SEASONS} from '@generic/data/assumptions';
import {times} from 'lodash';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedOruStaticRateElements from './shared/sharedOruStaticRateElements';

// Service Classification ("SC") 2; Secondary
const RATE_NAME = 'sc2S';

export default ({isOnMarketBasedRate}) => ({
  name: RATE_NAME,
  title: `SC 2`,
  rateElements: [
    ...sharedOruStaticRateElements,
    ...getMarketRateElements(isOnMarketBasedRate),
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 21}],
    },
    {
      rateElementType: 'DemandTiersInMonths',
      name: 'Demand Delivery Charge - over 5kW',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 1.83 : 3.1)),
          min: times(12, () => 0),
          max: times(12, () => 5),
        },
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 11.85 : 20.39)),
          min: times(12, () => 5),
          max: times(12, () => 'Infinity'),
        },
      ],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      name: 'Demand Delivery Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: SEASONS.map((season) => (season === 'WINTER' ? 4.21 : 16.66))}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Delivery Usage Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00786}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Dynamic Load Management Surcharge (DLM) (Added to ECA for billing purposes)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.06}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Energy Cost Adjustment (ECA) - Demand',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.19}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Cost Adjustment (ECA) - Energy',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00402}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Merchant Function Charge (MFC)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00297}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges - Mandatory DAHP',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 62.63 : 0}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'MSC Energy Charge - Rate 2S',
      billingCategory: 'supply',
      rateComponents: [{charge: isOnMarketBasedRate ? 0 : 0.04705}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Revenue Decoupling Mechanism Adj (RDM)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.01085}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Usage Charge - Summer',
      billingCategory: 'delivery',
      rateComponents: [{charge: SEASONS.map((season) => (season === 'WINTER' ? 0.0315 : 0.04263))}],
    },
    {
      rateElementType: 'BlockedTiersInMonths',
      name: 'Demand Delivery Charge - over 5kW',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 0.03673 : 0.04758)),
          min: times(12, () => 0),
          max: times(12, () => 1250),
        },
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 0.02868 : 0.02977)),
          min: times(12, () => 1250),
          max: times(12, () => 30000),
        },
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 0.0386 : 0.04041)),
          min: times(12, () => 30000),
          max: times(12, () => 'Infinity'),
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'VDER Cost Recovery - Added to CESS - Energy - Rates 2S, 20; VDER Cost Recovery - Added to ECA - Energy - Rates 2S, 20',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.0001 + 0.0003}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'VDER Cost Recovery - Added to ECA - Capacity - Rates 2S, 20',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.01}],
    },
  ],
});
