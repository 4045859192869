import {SEASONS} from '@generic/data/assumptions';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedOruStaticRateElements from './shared/sharedOruStaticRateElements';

// Service Classification ("SC") 9
const RATE_NAME = 'sc9';

export default ({isOnMarketBasedRate}) => ({
  name: RATE_NAME,
  title: `SC 9`,
  minKw: 1000,
  rateElements: [
    ...sharedOruStaticRateElements,
    ...getMarketRateElements(isOnMarketBasedRate),
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges - Mandatory DAHP',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 62.63 : 0}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 500}],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      name: 'Demand Charge by Period',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 0 : 24.08)),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22], // 8am to 11pm
          weekdays: [0, 1, 2, 3, 4],
          name: 'Period A',
        },
        {
          charge: SEASONS.map((season) => (season === 'WINTER' ? 11.31 : 0)),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22], // 8am to 11pm
          weekdays: [0, 1, 2, 3, 4],
          name: 'Period B',
        },
      ],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Dynamic Load Management Surcharge (DLM) (Added to ECA for billing purposes)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.05}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Merchant Function Charge (MFC)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00146}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Energy Cost Adjustment (ECA) - Demand',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.15}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Cost Adjustment (ECA) - Energy',
      billingCategory: 'delivery',
      rateComponents: [{charge: -0.0005}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Mandatory DAHP: Capacity Supply Charge',
      billingCategory: 'supply',
      rateComponents: [{charge: SEASONS.map((season) => (season === 'WINTER' ? 17.51 : 17.49))}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges',
      billingCategory: 'delivery',
      rateComponents: [{charge: 125.6}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Revenue Decoupling Mechanism Adj (RDM)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00359}],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'Usage',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: 0.00441,
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22], // 8am to 11pm
          daysOfWeek: [0, 1, 2, 3, 4],
          name: 'Period A, B',
        },
        {
          charge: 0.00164,
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 23], // 8am to 11pm
          daysOfWeek: [0, 1, 2, 3, 4],
          name: 'Period C - Weekdays',
        },
        {
          charge: 0.00164,
          daysOfWeek: [5, 6],
          name: 'Period C - Weekends',
        },
      ],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'VDER Cost Recovery - Added to ECA - Capacity - Rate 9',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.01}],
    },
  ],
});
