function hourBuilder(start, end) {
  const hours = new Array(24).fill(0);
  let i = start;
  if (start < end) {
    // eslint-disable-next-line no-plusplus
    for (i; i < end; i++) {
      hours[i] = 1;
    }
    return hours;
  }
  // eslint-disable-next-line no-plusplus
  for (i; i < 24; i++) {
    hours[i] = 1;
  }
  i = 0;
  // eslint-disable-next-line no-plusplus
  for (i; i <= end; i++) {
    hours[i] = 1;
  }
  return hours;
}

const TIME_PERIODS = [
  {
    key: '9pm_10am',
    label: '9pm to 10am (Overnight)',
    maxTimePeriod: 21,
    hours: hourBuilder(21, 10),
  },
  {
    key: '10am_12pm',
    label: '10am to 12pm',
    maxTimePeriod: 10,
    hours: hourBuilder(10, 12),
  },
  {
    key: '12p_7pm',
    label: '12pm to 7pm',
    maxTimePeriod: 14,
    hours: hourBuilder(12, 19),
  },
  {
    key: '7pm_9pmm',
    label: '7pm to 9pm',
    maxTimePeriod: 19,
    hours: hourBuilder(19, 21),
  },
];

export default TIME_PERIODS;
