import React from 'react';
import {Box, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const SUPPORTING_COPY = {
  sc2P: [' General non-residential secondary or primary service.'],
  sc2S: [' General non-residential secondary or primary service.'],
  sc3: ['Primary service to customers with demands greater than 100 kW and less than 1,000 kW'],
  sc9: [' Commercial customers taking primary service with demands exceeding 1,000 kW.'],
  sc20: ['Voluntary non-residential time of use service for secondary customers.'],
  sc21: [
    'Voluntary non-residential time of use service for primary service customers whose demands are less than 1,000 kW',
  ],
  sc25Rate1P: [
    'Standby Service Rates for customers who would otherwise be served under SC Nos. 2 or 20',
  ],
  sc25Rate1S: [
    'Standby Service Rates for customers who would otherwise be served under SC Nos. 2 or 20',
  ],
  sc25Rate2: [
    'Standby Service Rates for customers who would otherwise be served under SC Nos. 3 or 21',
  ],
  sc25Rate3: ['Standby Service Rates for customers who would otherwise be served under SC No. 9'],
};

export default function SupportingCopyPrintout({rateName}) {
  const supportCopy = SUPPORTING_COPY[rateName];
  return (
    <Box mb={5}>
      <Alert icon={false} severity="success" variant="outlined">
        {supportCopy.map((paragraph, idx) => (
          <Typography key={idx}>{paragraph}</Typography>
        ))}
      </Alert>
    </Box>
  );
}
