import React from 'react';
import {useIntl} from 'react-intl';
import ChargePanel from '@generic/components/ChargePanel';
import mean from 'lodash/mean';
import {formatAsThousands, formatAsDollars} from '@generic/functions/formatters';

export default function RateChargePanel({rateCalculator, loadProfile, rateTitle, rateComponent}) {
  const {formatMessage} = useIntl();

  const averageMonthlyEnergy = Math.round(mean(loadProfile.sumByMonth()));
  const annualMaxDemand = Math.round(loadProfile.max());
  const averageMonthlyDemand = Math.round(mean(loadProfile.maxByMonth()));

  const monthlyFixedCost = rateCalculator.annualCost({classifications: ['fixed']}) / 12;
  const monthlyDemandCost = rateCalculator.annualCost({classifications: ['demand']}) / 12;
  const monthlyEnergyCost = rateCalculator.annualCost({classifications: ['energy']}) / 12;

  const panels = [
    {
      id: 'fixed',
      description: formatMessage(
        {id: 'charging.content.costs.electricity.rateComponents.fixed.description'},
        {rateTitle: <b>{rateTitle}</b>}
      ),
      totalLabel: formatMessage({
        id: 'charging.content.costs.electricity.rateComponents.fixed.totalLabel',
      }),
      total: formatAsDollars(monthlyFixedCost),
    },
    {
      id: 'demand',
      description: formatMessage(
        {id: 'charging.content.costs.electricity.rateComponents.demand.description'},
        {annualDemand: <b>{annualMaxDemand} kW</b>, monthlyDemand: <b>{averageMonthlyDemand} kW</b>}
      ),
      totalLabel: formatMessage({
        id: 'charging.content.costs.electricity.rateComponents.demand.totalLabel',
      }),
      total: formatAsDollars(monthlyDemandCost),
    },
    {
      id: 'energy',
      description: formatMessage(
        {id: 'charging.content.costs.electricity.rateComponents.energy.description'},
        {totalUsage: <b>{formatAsThousands(averageMonthlyEnergy)} kWh</b>}
      ),
      totalLabel: formatMessage({
        id: 'charging.content.costs.electricity.rateComponents.energy.totalLabel',
      }),
      total: formatAsDollars(monthlyEnergyCost),
    },
  ];

  const {description, totalLabel, total} =
    panels.find((panel) => panel.id === rateComponent) || panels[0];

  return <ChargePanel description={description} total={total} totalLabel={totalLabel} />;
}
