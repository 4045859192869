import {useIntl} from 'react-intl';
import {Box, makeStyles, Typography, useTheme} from '@material-ui/core';
import {HorizontalBar} from 'react-chartjs-2';
import {formatAsThousands} from '@generic/functions/formatters';
import {useInputs} from '@bellawatt/use-inputs';
import times from 'lodash/times';
import calcAnnualChargerElectricityCostsAllRates from '@generic/functions/calcAnnualChargerElectricityCostsAllRates';

const useStyles = makeStyles(() => ({
  chartHeader: {
    fontWeight: '600',
  },
}));

const MAX_RATES_SHOWN = 4;

export default function PlanningRatesOverviewChart() {
  const classes = useStyles();
  const theme = useTheme();
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  const rawAnnualCosts = calcAnnualChargerElectricityCostsAllRates(inputs);
  const annualCosts = rawAnnualCosts.sort((a, b) => a.total - b.total).slice(0, MAX_RATES_SHOWN);

  const CHARGERS_COLORS = times(annualCosts.length, () => theme.palette.primary.main);

  const data = {
    labels: annualCosts.map(({title}) => title),
    datasets: [
      {
        label: formatMessage({id: 'planning.content.electricity.rateBreakdown.facility'}),
        data: annualCosts.map(({facility}) => facility),
        barPercentage: 0.7,
        backgroundColor: theme.palette.secondary.lighter,
      },
      {
        label: formatMessage({id: 'planning.content.electricity.rateBreakdown.chargers'}),
        data: annualCosts.map(({chargers}) => chargers),
        barPercentage: 0.7,
        backgroundColor: CHARGERS_COLORS,
      },
    ],
  };

  const options = {
    legend: false,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (dataItem, context) => {
          const {datasetIndex, index} = dataItem;
          const value = context.datasets[datasetIndex].data[index];
          // don't show zero values
          return value > 0
            ? `${context.datasets[dataItem.datasetIndex].label}: $${formatAsThousands(value)}`
            : null;
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8,
            callback: (value) => `$${formatAsThousands(value)}`,
          },
        },
      ],
    },
  };

  return (
    <Box mt={6}>
      <Typography align="center" variant="h6" className={classes.chartHeader}>
        {formatMessage(
          {id: 'charging.content.costs.electricity.rateBreakdown.cheapestRateTitle'},
          {rateTitle: annualCosts[0].title}
        )}
      </Typography>
      <Box height={300} mt={3}>
        <HorizontalBar data={data} options={options} />
      </Box>
    </Box>
  );
}
