import {Box, Dialog, makeStyles, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, {useState} from 'react';
import WizardHeader from '@generic/components/wizard/WizardHeader';
import GeneralInfo from '@generic/components/wizard/GeneralInfo';
import Chargers from '@generic/pages/SitePlanner/wizard/Chargers';
import Utilization from '@generic/pages/SitePlanner/wizard/Utilization';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: theme.palette.gray[100],
    minWidth: 755,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      maxWidth: '100% !important',
      margin: '10px 4px',
      width: 'calc(100% - 16px)',
    },
  },
  body: {
    minHeight: 600,
    margin: 20,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.gray[500]}`,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      margin: '45px 55px',
    },
  },
  content: {
    flexGrow: 1,
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  backButton: {
    border: 'none',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 700,
    fontSize: '1rem',
    background: 'transparent',
    cursor: 'pointer',
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(3),
    },
  },
  nextButton: {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.dark,
    fontWeight: 700,
    fontSize: '1rem',
    padding: '12px 14px',
    minWidth: 150,
    borderRadius: 86,
    cursor: 'pointer',
    marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
}));

export default function PlanningWizard() {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const tabs = [
    {
      label: formatMessage({id: 'wizard.planning.intro.label'}),
      content: (props) => (
        <GeneralInfo
          {...props}
          header={formatMessage({id: 'wizard.planning.intro.header'})}
          subheader={formatMessage({id: 'wizard.planning.intro.subheader'})}
        />
      ),
    },
    {
      label: formatMessage({id: 'wizard.planning.chargers.label'}),
      content: Chargers,
    },
    {
      label: formatMessage({id: 'wizard.planning.utilization.label'}),
      content: Utilization,
    },
  ];

  const {planningWizardShown, currentRate, setInput, ...restOfInputs} = useInputs();

  // keep track of dialog state separately, set with useInputs only when dialog is closed
  // computed property for currentRate is ignored for local version of state
  const [workingInputs, setWorkingInputs] = useState({
    ...restOfInputs,
    currentRate: currentRate.name,
  });

  const handleClose = (e, reason) => {
    // ignore working inputs if dialog is closed prematurely
    if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason === 'closeButtonClick') {
      setInput({planningWizardShown: true});
    } else {
      setInput({planningWizardShown: true, ...workingInputs});
    }
  };

  const handleInputChange = (key, value) => setWorkingInputs((prev) => ({...prev, [key]: value}));

  const [activeTab, setActiveTab] = useState(0);
  const onBackClick = () => setActiveTab((prev) => prev - 1);

  // close dialog after final tab is submitted
  const onNextClick = () => {
    if (activeTab >= tabs.length - 1) {
      handleClose();
    } else {
      setActiveTab((prev) => prev + 1);
    }
  };

  const ContentComponent = tabs[activeTab].content;

  // show the next step, unless we're on the last tab, in which case show 'Submit'
  const getNextTabLabel = (activeTab) =>
    activeTab >= tabs.length - 1
      ? formatMessage({id: 'wizard.submitButton'})
      : formatMessage({id: 'wizard.nextButton'}, {label: tabs[activeTab + 1].label});

  return (
    <Dialog
      open
      onClose={handleClose}
      scroll="body"
      PaperProps={{
        className: classes.dialogPaper,
        'aria-label': formatMessage({id: 'wizard.charging.dialogLabel'}),
      }}
      fullWidth
    >
      <IconButton
        className={classes.closeIcon}
        onClick={(e) => handleClose(e, 'closeButtonClick')}
        aria-label={formatMessage({id: 'wizard.closeButtonLabel'})}
      >
        <CloseIcon />
      </IconButton>
      <Box className={classes.body}>
        <WizardHeader tabs={tabs} activeTab={activeTab} />
        <Box p={3} className={classes.content}>
          <ContentComponent inputs={workingInputs} handleInputChange={handleInputChange} />
        </Box>
        <Box p={3} className={classes.footer}>
          {activeTab > 0 && (
            <button type="button" onClick={onBackClick} className={classes.backButton}>
              {formatMessage({id: 'wizard.backButton'})}
            </button>
          )}
          <button type="submit" onClick={onNextClick} className={classes.nextButton}>
            {getNextTabLabel(activeTab)}
          </button>
        </Box>
      </Box>
    </Dialog>
  );
}
