import hourlyMarketPricesZoneG from './hourlyMarketPricesZoneG';

export default function getMarketRateElements(isOnMarketBasedRate) {
  return isOnMarketBasedRate
    ? [
        {
          rateElementType: 'HourlyEnergy',
          billingCategory: 'supply',
          name: 'Mandatory Day Ahead Hourly Pricing (MDAH)',
          priceProfile: hourlyMarketPricesZoneG,
        },
      ]
    : [];
}
