import sc2P from './sc2P';
import sc2S from './sc2S';
import sc3 from './sc3';
import sc9 from './sc9';
import sc20 from './sc20';
import sc21 from './sc21';
import sc25Rate1P from './sc25Rate1P';
import sc25Rate1S from './sc25Rate1S';
import sc25Rate2 from './sc25Rate2';
import sc25Rate3 from './sc25Rate3';

const RATES_SECONDARY = [sc2S, sc3, sc9, sc20, sc25Rate1S];
const RATES_PRIMARY = [sc2P, sc21, sc25Rate1P, sc25Rate2, sc25Rate3];
const RATES = [...RATES_PRIMARY, ...RATES_SECONDARY];

const isRateApplicable = ({minKw, maxKw}, demand) => {
  // both are defined, demand has to be in between
  if (minKw !== undefined && maxKw !== undefined) {
    return demand >= minKw && demand <= maxKw;
  }
  // only min is defined, demand has to be greater
  if (minKw !== undefined) {
    return demand >= minKw;
  }
  // only max is defined, demand has to be less
  if (maxKw !== undefined) {
    return demand <= maxKw;
  }
  // neither is defined, always applicable
  return true;
};

export const getRates = ({voltageLevel, isOnMarketBasedRate}, demand) => {
  // voltage level input determines charges for rate components
  const allRates =
    voltageLevel === 'high'
      ? RATES_PRIMARY.map((rateFn) => rateFn({isOnMarketBasedRate}))
      : RATES_SECONDARY.map((rateFn) => rateFn({isOnMarketBasedRate}));

  // if rate has minKw or maxKw, make sure it's applicable to user's max demand
  const applicableRates = allRates.filter((rate) => isRateApplicable(rate, demand));

  return applicableRates;
};

export const getRatesIgnoringDemand = (inputs) => [
  ...getRates(inputs, Number.NEGATIVE_INFINITY),
  ...getRates(inputs, Number.POSITIVE_INFINITY),
];

export default RATES;
