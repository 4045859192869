import {SEASONS} from '@generic/data/assumptions';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedOruStaticRateElements from './shared/sharedOruStaticRateElements';

// Service Classification ("SC") 3
const RATE_NAME = 'sc3';

export default ({isOnMarketBasedRate}) => ({
  name: RATE_NAME,
  title: `SC 3`,
  minKw: 100,
  rateElements: [
    ...sharedOruStaticRateElements,
    ...getMarketRateElements(isOnMarketBasedRate),
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 120}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Dynamic Load Management Surcharge (DLM) (Added to ECA for billing purposes)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.06}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Energy Cost Adjustment (ECA) - Demand',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.25}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Cost Adjustment (ECA) - Energy',
      billingCategory: 'delivery',
      rateComponents: [{charge: -0.00009}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Merchant Function Charge (MFC)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00146}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 0 : 23.62}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges - Mandatory DAHP',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 62.63 : 0}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Revenue Decoupling Mechanism Adj (RDM)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00267}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Usage Charge',
      billingCategory: 'delivery',
      rateComponents: [
        {charge: SEASONS.map((season) => (season === 'WINTER' ? 0.00696 : 0.00696))},
      ],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'VDER Cost Recovery - Added to ECA - Capacity - Rates 3, 21',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.03}],
    },

    {
      rateElementType: 'MonthlyDemand',
      name: 'Demand Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: SEASONS.map((season) => (season === 'WINTER' ? 12.63 : 22.32))}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'MSC Energy Charge - Rate 2P, 3, 25 I, 25 II',
      billingCategory: 'supply',
      rateComponents: [{charge: isOnMarketBasedRate ? 0 : 0.04461}],
    },
  ],
});
