import React, {useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import RateBreakdownSelector from '@generic/pages/ChargingCalculator/RateBreakdownSelector';
import RateComponentCategoryChart from '@generic/pages/ChargingCalculator/RateComponentCategoryChart';
import RateChargePanel from '@generic/pages/ChargingCalculator/RateChargePanel';
import SupportingCopyPrintout from '@generic/pages/ChargingCalculator/SupportingCopyPrintout';
import ChargingSiteMonthlyDemand from '@generic/pages/ChargingCalculator/ChargingSiteMonthlyDemand';
import ChargingSiteLoadProfile from '@generic/pages/ChargingCalculator/ChargingSiteLoadProfile';
import ElectricityBill from '@generic/pages/ChargingCalculator/ElectricityBill';
import calcVehicleSetFacilityLoadProfile from '@generic/functions/calcVehicleSetFacilityLoadProfile';
import calcScaledFacilityLoadProfile from '@generic/functions/calcScaledFacilityLoadProfile';
import {getRates} from '@generic/data/rates';
import {YEAR} from '@generic/data/assumptions';
import {useInputs} from '@bellawatt/use-inputs';
import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';

export default function ChargingRateBreakdown() {
  const inputs = useInputs();
  const {separatelyMetered} = inputs;
  RateCalculator.shouldValidate = false;

  const evLoadProfileData = calcVehicleSetFacilityLoadProfile(inputs);
  const evLoadProfile = new LoadProfile(evLoadProfileData, {year: YEAR});
  const totalLoadProfile = separatelyMetered
    ? evLoadProfile
    : evLoadProfile.aggregate(calcScaledFacilityLoadProfile(inputs));

  const maxDemand = totalLoadProfile.max();

  const rates = getRates(inputs, maxDemand);

  const [rate, setRate] = useState(rates[0]);
  const [selectedRateComponent, setSelectedRateComponent] = useState('energy');

  const handleChange = (rateName) => {
    setRate(rates.find((r) => r.name === rateName) || rates[0]);
  };

  const rateCalculator = new RateCalculator({
    ...rate,
    loadProfile: totalLoadProfile,
  });

  return (
    <Box mt={5}>
      <RateBreakdownSelector
        rateName={rate.name}
        handleChange={handleChange}
        maxDemand={maxDemand}
      />

      <SupportingCopyPrintout rateName={rate.name} />

      <Grid container>
        <Grid item xs={12} lg={6}>
          <RateComponentCategoryChart
            rateCalculator={rateCalculator}
            onHover={setSelectedRateComponent}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box px={10} display="flex" justifyContent="center">
            <RateChargePanel
              rateCalculator={rateCalculator}
              loadProfile={totalLoadProfile}
              rateTitle={rate.title}
              rateComponent={selectedRateComponent}
            />
          </Box>
        </Grid>
      </Grid>

      <ChargingSiteMonthlyDemand />
      <ChargingSiteLoadProfile />
      <ElectricityBill
        rateCalculator={rateCalculator}
        loadProfile={totalLoadProfile}
        rateTitle={rate.title}
      />
    </Box>
  );
}
