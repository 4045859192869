import React from 'react';
import {Box} from '@material-ui/core';
import AccordionPanel from '@generic/components/AccordionPanel';
import PlanningRatesOverviewChart from '@generic/pages/SitePlanner/PlanningRatesOverviewChart';
import PlanningRateBreakdown from '@generic/pages/SitePlanner/PlanningRateBreakdown';
import {useIntl} from 'react-intl';

export default function PlanningElectricity() {
  const {formatMessage} = useIntl();

  return (
    <Box mt={4}>
      <AccordionPanel
        summary={formatMessage({id: 'electricRateExplainer.summary'})}
        details={formatMessage({id: 'electricRateExplainer.details'})}
      />

      <Box px={4}>
        <PlanningRatesOverviewChart />

        <PlanningRateBreakdown />
      </Box>
    </Box>
  );
}
