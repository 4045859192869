import {Switch, Route} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Inputs} from '@bellawatt/use-inputs';
import getDefaults from '@generic/functions/getDefaults';
import I18NextProvider from '@generic/utils/I18NextProvider';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '@generic/config/theme/theme';
import ChargingCalculator from '@generic/pages/ChargingCalculator/ChargingCalculator';
import SitePlanner from '@generic/pages/SitePlanner/SitePlanner';
import ReactGA from 'react-ga4';
import ReactIntlProvider from '@generic/utils/ReactIntlProvider';
import computed from '@generic/use-inputs/computed';
import Home from './pages/Home';

import './assets/stylesheets/icons.css';

const DEFAULT_LOCALE = 'en';

export default function ORU() {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  }

  return (
    <I18NextProvider>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Inputs computed={computed} defaults={getDefaults()}>
            <ReactIntlProvider defaultLocale={DEFAULT_LOCALE}>
              <Switch>
                <Route path="/sites">
                  <SitePlanner />
                </Route>
                <Route path="/fleets">
                  <ChargingCalculator />
                </Route>
                <Route path="/" exact>
                  <Home />
                </Route>
              </Switch>
            </ReactIntlProvider>
          </Inputs>
        </ThemeProvider>
      </HelmetProvider>
    </I18NextProvider>
  );
}
