import {useIntl} from 'react-intl';
import {Box, makeStyles, Typography, useTheme} from '@material-ui/core';
import {HorizontalBar} from 'react-chartjs-2';
import {formatAsThousands} from '@generic/functions/formatters';
import calcAnnualFossilFuelCosts from '@generic/functions/calcAnnualFossilFuelCosts';
import {useInputs} from '@bellawatt/use-inputs';
import times from 'lodash/times';
import calcAnnualVehicleElectricityCostsAllRates from '@generic/functions/calcAnnualVehicleElectricityCostsAllRates';
import {RateCalculator} from '@bellawatt/electric-rate-engine';
import calcScaledFacilityLoadProfile from '@generic/functions/calcScaledFacilityLoadProfile';

const useStyles = makeStyles(() => ({
  chartHeader: {
    fontWeight: '600',
  },
}));

const MAX_RATES_SHOWN = 3;

export default function ChargingRatesOverviewChart({compareToFossil}) {
  const classes = useStyles();
  const theme = useTheme();
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  const {separatelyMetered, currentRate} = inputs;
  RateCalculator.shouldValidate = false;

  const fossilFuelCosts = calcAnnualFossilFuelCosts(inputs);

  let fossilFuelFacilityCost = 0;
  if (!separatelyMetered) {
    const scaledFacilityLoadProfile = calcScaledFacilityLoadProfile(inputs);

    fossilFuelFacilityCost = new RateCalculator({
      ...currentRate,
      loadProfile: scaledFacilityLoadProfile,
    }).annualCost();
  }

  const rawAnnualCosts = calcAnnualVehicleElectricityCostsAllRates(inputs);
  const annualCosts = rawAnnualCosts.sort((a, b) => a.total - b.total).slice(0, MAX_RATES_SHOWN);

  const EV_COLORS = times(annualCosts.length, () => theme.palette.primary.main);

  const data = {
    labels: compareToFossil
      ? ['Fossil Fuel', ...annualCosts.map(({title}) => title)]
      : [...annualCosts.map(({title}) => title)],
    datasets: [
      {
        label: formatMessage({id: 'charging.content.costs.electricity.rateBreakdown.facility'}),
        data: compareToFossil
          ? [fossilFuelFacilityCost, ...annualCosts.map(({facility}) => facility)]
          : annualCosts.map(({facility}) => facility),
        barPercentage: 0.7,
        backgroundColor: theme.palette.secondary.lighter,
      },
      {
        label: formatMessage({id: 'charging.content.costs.electricity.rateBreakdown.ev'}),
        data: compareToFossil
          ? [fossilFuelCosts, ...annualCosts.map(({ev}) => ev)]
          : annualCosts.map(({ev}) => ev),
        barPercentage: 0.7,
        backgroundColor: compareToFossil ? [theme.palette.grey[800], ...EV_COLORS] : EV_COLORS,
      },
    ],
  };

  const options = {
    legend: false,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (dataItem, context) => {
          const {datasetIndex, index} = dataItem;
          const value = context.datasets[datasetIndex].data[index];
          // don't show zero values
          return value > 0
            ? `${context.datasets[dataItem.datasetIndex].label}: $${formatAsThousands(value)}`
            : null;
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8,
            callback: (value) => `$${formatAsThousands(value)}`,
          },
        },
      ],
    },
  };

  return (
    <Box mt={6}>
      <Typography align="center" variant="h6" className={classes.chartHeader}>
        {formatMessage(
          {id: 'charging.content.costs.electricity.rateBreakdown.cheapestRateTitle'},
          {rateTitle: annualCosts[0].title}
        )}
      </Typography>
      <Box height={300} mt={3}>
        <HorizontalBar data={data} options={options} />
      </Box>
    </Box>
  );
}
