import getMarketRateElements from './shared/getMarketRateElements';
import sharedOruStaticRateElements from './shared/sharedOruStaticRateElements';

// Service Classification ("SC") 20
const RATE_NAME = 'sc20';

export default ({isOnMarketBasedRate}) => ({
  name: RATE_NAME,
  title: `SC 20`,
  minKw: 5,
  rateElements: [
    ...sharedOruStaticRateElements,
    ...getMarketRateElements(isOnMarketBasedRate),

    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges - Mandatory DAHP',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 62.63 : 0}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 20}],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      name: 'Demand Charge by Period',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: 30.24,
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], // 8am to 6pm
          months: [5, 6, 7, 8],
          name: 'Period I',
        },
        {
          charge: 12.94,
          hourStarts: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 10p to 8pm
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: 'Period II',
        },
        {
          charge: 0.45,
          name: 'Period III',
        },
      ],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Dynamic Load Management Surcharge (DLM) (Added to ECA for billing purposes)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.06}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'Energy Cost Adjustment (ECA) - Demand',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.19}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Cost Adjustment (ECA) - Energy',
      billingCategory: 'delivery',
      rateComponents: [{charge: -0.00001}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Merchant Function Charge (MFC)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00297}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges',
      billingCategory: 'delivery',
      rateComponents: [{charge: 23.3}],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'MSC Energy Charge - Rate 20',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: 0.05297,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [13, 14, 15, 16, 17, 18],
          name: 'On Peak - Summer Weekdays',
        },
        {
          charge: 0.05297,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
          name: 'On Peak - Winter Weekdays',
        },
        {
          charge: 0.04559,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 19, 20, 21, 22, 23],
          name: 'Off Peak - Summer Weekdays',
        },
        {
          charge: 0.04559,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 21, 22, 23],
          name: 'Off Peak - Winter Weekdays',
        },
        {
          charge: 0.04559,
          daysOfWeek: [5, 6],
          hourStarts: [13, 14, 15, 16, 17, 18],
          name: 'Off Peak - Weekends',
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Revenue Decoupling Mechanism Adj (RDM)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.01085}],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'Usage Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: 0.03592,
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], // 8am to 6pm
          months: [5, 6, 7, 8],
          name: 'Period I',
        },
        {
          charge: 0.00115,
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23],
          months: [5, 6, 7, 8],
          name: 'Period III', // inverse of Period I portion
        },
        {
          charge: 0.00863,
          hourStarts: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 10p to 8pm
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: 'Period II',
        },
        {
          charge: 0.00115,
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 21, 22, 23],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: 'Period III', // inverse of Period II portion
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'VDER Cost Recovery - Added to CESS - Energy - Rates 2S, 20',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.0003}],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'VDER Cost Recovery - Added to ECA - Capacity - Rates 2S, 20',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.01}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'VDER Cost Recovery - Added to ECA - Energy - Rates 2S, 20',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.0001}],
    },
  ],
});
