import {Helmet} from 'react-helmet-async';
import oruFavicon from '@generic/assets/images/favicon.ico';

export default function FaviconMetaTag() {
  return (
    <Helmet>
      <link rel="icon" type="image/png" href={oruFavicon} />
    </Helmet>
  );
}
