import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import DropdownInput from '@generic/components/inputs/DropdownInput';
import SliderInput from '@generic/components/inputs/SliderInput';
import buildingTypes from '@generic/data/buildingTypes';
import booleanOptions from '@generic/data/booleanOptions';
import currentRates from '@generic/data/currentRates';
import {formatAsDollarsRounded} from '@generic/functions/formatters';
import SmallSourceLink from '@generic/components/SmallSourceLink';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1.125rem',
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  subheader: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
  },
}));

export default function GeneralInfo({inputs, handleInputChange, header, subheader}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const {separatelyMetered, buildingType, monthlyElectricBill, currentRate} = inputs;

  return (
    <Box>
      <Typography variant="h2" className={classes.header}>
        {header}
      </Typography>
      <Typography className={classes.subheader}>{subheader}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <DropdownInput
            title={formatMessage({
              id: 'charging.inputs.advanced.separatelyMetered.title',
            })}
            tooltip={formatMessage({
              id: 'charging.inputs.advanced.separatelyMetered.tooltip',
            })}
            options={booleanOptions.map(({id, translationKey}) => ({
              value: id,
              children: formatMessage({id: translationKey}),
            }))}
            value={separatelyMetered ? 'yes' : 'no'}
            onChange={(e) => handleInputChange('separatelyMetered', e.target.value === 'yes')}
            variant="gray"
            fullWidth
          />
        </Grid>
        {!separatelyMetered && (
          <>
            <Grid item xs={12} md={6}>
              <DropdownInput
                title={formatMessage({
                  id: 'charging.inputs.advanced.buildingType.title',
                })}
                tooltip={formatMessage({
                  id: 'charging.inputs.advanced.buildingType.tooltip',
                })}
                options={buildingTypes.map(({id, translationKey}) => ({
                  value: id,
                  children: formatMessage({id: translationKey}),
                }))}
                value={buildingType}
                onChange={(e) => handleInputChange('buildingType', e.target.value)}
                variant="gray"
                fullWidth
              />
              <SmallSourceLink
                text="Source: NREL"
                link="https://www.nrel.gov/buildings/end-use-load-profiles.html"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SliderInput
                label={formatMessage({
                  id: 'charging.inputs.advanced.monthlyBillSliderLabel',
                })}
                tooltip={formatMessage({
                  id: 'charging.inputs.advanced.monthlyBillSliderTooltip',
                })}
                value={monthlyElectricBill}
                formatFn={formatAsDollarsRounded}
                onChange={(value) => handleInputChange('monthlyElectricBill', value)}
                min={1000}
                max={20000}
                step={1000}
                variant="gray"
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownInput
                title={formatMessage({
                  id: 'charging.inputs.advanced.currentRate.title',
                })}
                tooltip={formatMessage({
                  id: 'charging.inputs.advanced.currentRate.tooltip',
                })}
                options={currentRates.map(({id, translationKey}) => ({
                  value: id,
                  children: formatMessage({id: translationKey}),
                }))}
                value={currentRate}
                onChange={(e) => handleInputChange('currentRate', e.target.value)}
                variant="gray"
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
