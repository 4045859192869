export default [
  {
    rateElementType: 'MonthlyEnergy',
    name: 'System Benefits Charge (SBC)',
    billingCategory: 'delivery',
    rateComponents: [{charge: 0.00575}],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Transition Adjustment for Competitive Services (TACS)',
    billingCategory: 'delivery',
    rateComponents: [{charge: -0.00053}],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'ZEC/REC Component, if over 300 kW',
    billingCategory: 'supply',
    rateComponents: [{charge: 0.00618}],
  },
];
