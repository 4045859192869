import getMarketRateElements from './shared/getMarketRateElements';
import sharedOruStaticRateElements from './shared/sharedOruStaticRateElements';

// Service Classification ("SC") 21
const RATE_NAME = 'sc21';

export default ({isOnMarketBasedRate}) => ({
  name: RATE_NAME,
  title: `SC 21`,
  minKw: 1000,
  rateElements: [
    ...sharedOruStaticRateElements,
    ...getMarketRateElements(isOnMarketBasedRate),
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [{charge: 163}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges - Mandatory DAHP',
      billingCategory: 'delivery',
      rateComponents: [{charge: isOnMarketBasedRate ? 62.63 : 0}],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      name: 'Demand Charge by Period',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: 30.52,
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], // 8am to 6pm
          months: [5, 6, 7, 8],
          name: 'Period I',
        },
        {
          charge: 10.76,
          hourStarts: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 10p to 8pm
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: 'Period II',
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Merchant Function Charge (MFC)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00146}],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Metering Charges',
      billingCategory: 'delivery',
      rateComponents: [{charge: 15.86}],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Revenue Decoupling Mechanism Adj (RDM)',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.00267}],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'MSC Energy Charge - Rate 21',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: 0.05037,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [13, 14, 15, 16, 17, 18],
          name: 'On Peak - Summer Weekdays',
        },
        {
          charge: 0.05037,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
          name: 'On Peak - Winter Weekdays',
        },
        {
          charge: 0.04312,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 19, 20, 21, 22, 23],
          name: 'Off Peak - Summer Weekdays',
        },
        {
          charge: 0.04312,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 21, 22, 23],
          name: 'Off Peak - Winter Weekdays',
        },
        {
          charge: 0.04312,
          daysOfWeek: [5, 6],
          hourStarts: [13, 14, 15, 16, 17, 18],
          name: 'Off Peak - Weekends',
        },
      ],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'Usage Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: 0.01553,
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], // 8am to 6pm
          months: [5, 6, 7, 8],
          name: 'Period I',
        },
        {
          charge: 0.00136,
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23],
          months: [5, 6, 7, 8],
          name: 'Period III', // inverse of Period I portion
        },
        {
          charge: 0.01553,
          hourStarts: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 10p to 8pm
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: 'Period II',
        },
        {
          charge: 0.00136,
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 21, 22, 23],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: 'Period III', // inverse of Period II portion
        },
      ],
    },
    {
      rateElementType: 'MonthlyDemand',
      name: 'VDER Cost Recovery - Added to ECA - Capacity - Rates 3, 21',
      billingCategory: 'delivery',
      rateComponents: [{charge: 0.03}],
    },
  ],
});
