import {makeStyles, Typography, Box} from '@material-ui/core';
import {useIntl} from 'react-intl';
import FooterLinks from './FooterLinks';
import SecondaryFooter from './SecondaryFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gray[300],
  },
  contactContainer: {
    padding: '0 10px 40px 10px',
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  contactContainerTop: {
    padding: '40px 10px 0 10px',
  },
  contactLink: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px 80px',
    textDecoration: 'none',
    textAlign: 'center',
    borderRadius: '86px',
    transition: 'all 200ms ease',
  },
  contactLinkText: {
    color: theme.palette.secondary.dark,
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  helpText: {
    fontWeight: 600,
    fontSize: '1.25rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
}));

export default function Footer() {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Box display="flex" justifyContent="center" className={classes.contactContainerTop}>
        <Typography className={classes.helpText}>
          {formatMessage({id: 'footer.helpText'})}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" className={classes.contactContainer}>
        <a href={formatMessage({id: 'emailAnchorLink'})} className={classes.contactLink}>
          <Typography className={classes.contactLinkText}>
            {formatMessage({id: 'footer.contactLink'})}
          </Typography>
        </a>
      </Box>
      <FooterLinks />
      <SecondaryFooter />
    </footer>
  );
}
