import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${theme.palette.gray[300]}`,
    padding: '1rem',
    margin: '0 1rem',
    minHeight: '100%',
  },
  description: {
    fontSize: '1.125rem',
    flexGrow: 1,
  },
  totalLabel: {
    marginTop: '1.25rem',
    color: theme.palette.gray[500],
  },
  total: {
    fontSize: '4.5rem',
    fontWeight: '600',
  },
}));

export default function ChargePanel({description, total, totalLabel}) {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography variant="body2" className={classes.description}>
        {description}
      </Typography>
      <Typography variant="body2" className={classes.totalLabel}>
        {totalLabel}
      </Typography>
      <Typography variant="h4" className={classes.total}>
        {total}
      </Typography>
    </Box>
  );
}
