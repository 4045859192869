const translations = {
  en: {
    translation: {
      emailAnchorLink:
        'mailto:EV@oru.com?subject=Interest in discussing Orange and Rockland EV charging rate calculator&body=Hello, I am using the EV charging calculator and am interested in learning more about EV charging rates at Orange and Rockland. Can someone please contact me?',
      home: {
        header: 'Plan your EV charging costs with Orange & Rockland.',
        subheader: "Electric vehicles are the future. Let's plan for it, together.",
        features: {
          header: 'Everything you need to electrify.',
          incentives: {
            title: 'Incentives',
            text: 'Identify available incentives to increase your expected savings.',
          },
          rates: {
            title: 'Rate Comparison',
            text: 'Identify the right Orange & Rockland rate for your project.',
          },
          savings: {
            title: 'Fuel Savings',
            text: 'Calculate the savings of electrification.',
          },
          analysis: {
            title: 'Utility Bills Analysis',
            text: 'Evaluate how your charging project will affect your Orange & Rockland bill.',
          },
        },
        impact: {
          header: 'Electrification can make the biggest impact.',
          subheader: 'Electrifying fleets reduces more emissions than any other single driver.',
          numberOfFleets: 'There are almost 1 million fleet vehicles in America',
          fleetPercentage: '17% of new vehicles purchased are for fleets',
        },
        cta: "Let's make a plan, together.",
      },
      footer: {
        helpText: "Want to learn more? We're here to help.",
        contactLink: 'Email an Orange & Rockland Representative',
        links: {
          site: {
            header: 'Orange & Rockland',
          },
          social: {
            facebookLink: 'https://www.facebook.com/ORUConnect',
            twitterLink: 'https://twitter.com/ORUConnect',
            youtubeLink: 'https://www.youtube.com/user/ORUConnect',
            linkedInLink: 'https://www.linkedin.com/company/oruconnect/',
          },
        },
        secondary: {
          links: {
            privacyPolicyLink: 'https://www.oru.com/en/privacy-statement',
            // ORU's footer link redirects to ConEd
            accessibilityPolicyLink: 'https://www.conedison.com/accessibility-policy',
            termsOfServiceLink: 'https://www.oru.com/en/terms',
            homeNetLink: 'https://cevo.coned.com/',
          },
          copyright: '© 2016 - 2020 Orange and Rockland Utilities, Inc. All Rights Reserved.',
        },
      },
      wizard: {
        charging: {
          vehicleTypes: {
            subheader:
              "Orange & Rockland program and incentive eligibility depends on the vehicle class selected; please speak with an Orange & Rockland representative to confirm eligibility. You'll be able to add more vehicle types after completing these four steps.",
          },
        },
      },
      seo: {
        title: 'Orange & Rockland Charging Calculator',
        description:
          "Plan Your EV Charging Costs With Orange & Rockland. Electric vehicles are the future. Let's plan for it, together.",
      },
      businessIncentiveRate: {
        link: 'https://www.coned.com/en/commercial-industrial/economic-development/business-incentive-rate',
      },
      electricRateExplainer: {
        details:
          'We use the information you enter about your charger type(s) and location to estimate your annual electric charges. The calculation includes supply charges and certain fees, assuming Orange & Rockland supplies electricity to you and assuming prices from the 2019 calendar year. If you get your electricity from a different supplier, these total bill estimates may not apply. You may choose to separately meter your EV charging load or combine it with your building load. If your EV charging load is combined with the building load, we estimate your building load shape from the 2021 NREL Load Shape Study to estimate your pre-existing typical monthly bill. Additional taxes such as sales tax are not included here, and would be added to your actual bill.',
      },
    },
  },
};

export default translations;
